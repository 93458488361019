import React from "react"
import Layout from "../components/Layout"

const NotFoundPage = ({ location }) => {
  const pageSlug = "notfound"
  const pageTitle = "NOT FOUND"

  return (
    <Layout pageSlug={pageSlug} location={location} pageTitle={pageTitle}>
      <div className="container" style={{ paddingTop: "10rem" }}>
        <div className="head__wrapper">
          <h2 className="head font-headline">{pageTitle}</h2>
        </div>
        <div className="text-center" style={{ minHeight: "50vh" }}>
          <p>You just hit a route that doesn&#39;t exist... sorry.</p>
          <p>ごめんなさい。このページは存在しません。</p>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
